import styled from "styled-components";
import { StyledTitle } from "components/core/Typography/Title/index.style";
import { Link } from "gatsby";

const TermContainer = styled.div`
  display: block;
  margin-bottom: 64px;

  ${StyledTitle} {
    margin-top: 16px;
  }
`;

const TermTitle = styled.h2`
  font-weight: 400;
  line-height: 1.2;
  color: ${(props) => props.theme.titleColor};
  margin-top: 0.3em;
  font-size: 24px;
`;

const TermDescription = styled.span``;

const TermBodyContainer = styled.div`
  padding: 16px;

  h2 {
    font-size: 24px;
    color: ${(props) => props.theme.titleColor};
  }

  h3 {
    font-size: 18px;
    margin-top: 16px;
    color: ${(props) => props.theme.titleColor};
  }

  p {
    font-size: 20px;
    line-height: 34px;
    color: ${(props) => props.theme.titleColor};
  }

  a {
    font-size: 20px;
    line-height: 34px;
  }
`;

const GlossaryLettersContainer = styled.div`
  padding: 64px 32px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
`;

const GlossaryLetterLink = styled(Link)`
  background: ${(props) => props.theme.accentLighten80Color};
  padding: 8px 16px;
  border-radius: 8px;
  width: 50px;
  text-align: center;

  :hover {
    background: ${(props) => props.theme.accentColor};
    color: ${(props) => props.theme.whiteColor};
  }
`;

export {
  TermContainer,
  TermTitle,
  TermDescription,
  TermBodyContainer,
  GlossaryLettersContainer,
  GlossaryLetterLink,
};
