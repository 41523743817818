import * as React from "react";
import { useState } from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import PlainHeader from "components/ui/base/PlainHeader";
import { useTranslation } from "react-i18next";
import { graphql, Link } from "gatsby";
import { PageContainer } from "styles/global.style";
import { Term, TermQueryResult } from "app-types/term";
import { getGlossaryPathBySlug } from "utils/path";

import {
  GlossaryLetterLink,
  GlossaryLettersContainer,
  TermContainer,
  TermDescription,
  TermTitle,
} from "styles/glossary.style";
import Input from "components/core/Input";

interface GlossaryPageProps {
  data: TermQueryResult;
  location: Location;
}

type GroupedTerms = {
  [key: string]: Term[];
};

export default ({ data, location }: GlossaryPageProps) => {
  const terms = data.allContentfulTerm.nodes;
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();

  const filterTerms = (terms: Term[], query: string) => {
    return terms.filter((term) =>
      term.term.toLowerCase().includes(query.toLowerCase())
    );
  };

  const getGroupedTerms = () => {
    const filteredTerms = filterTerms(terms, searchQuery);
    const grouped: GroupedTerms = {};
    filteredTerms.forEach((term) => {
      let firstLetter = term.term[0].toUpperCase();

      if (!isNaN(firstLetter as any)) {
        firstLetter = "#";
      }

      if (!grouped[firstLetter]) {
        grouped[firstLetter] = [];
      }
      grouped[firstLetter].push(term);
    });
    return grouped;
  };

  const groupedTerms = getGroupedTerms();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.glossary.title")}
        description={t("pages.glossary.description")}
      />
      <PlainHeader
        title={t("pages.glossary.title")}
        description={t("pages.glossary.description")}
        maxWidth={800}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Glossary",
            path: "/glossary/",
          },
        ]}
      />

      <PageContainer>
        <Input
          type="text"
          placeholder={t("pages.glossary.search.placeholder")}
          value={searchQuery}
          onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
            setSearchQuery(e.target.value)
          }
        />
        <GlossaryLettersContainer>
          {Object.keys(groupedTerms).map((letter) => (
            <GlossaryLetterLink to={`#${letter}`} key={letter}>
              {letter}
            </GlossaryLetterLink>
          ))}
        </GlossaryLettersContainer>
        {Object.entries(groupedTerms).map(([letter, terms]) => (
          <section id={letter} key={letter}>
            <h2>{letter}</h2>
            {terms.map((term: Term) => (
              <TermContainer key={term.slug}>
                <TermTitle>
                  <Link to={getGlossaryPathBySlug(term.slug)}>{term.term}</Link>
                </TermTitle>
                <TermDescription>{term.metaDescription}</TermDescription>
              </TermContainer>
            ))}
          </section>
        ))}
      </PageContainer>
    </GlobalWrapper>
  );
};

export const glossaryPageQuery = graphql`
  query {
    allContentfulTerm(
      sort: { order: ASC, fields: term }
      filter: { node_locale: { eq: "en" } }
    ) {
      nodes {
        term
        slug
        metaDescription
        category
        iteration {
          content
        }
        relatedTerms {
          content
        }
        body {
          childMarkdownRemark {
            htmlAst
          }
        }
      }
    }
  }
`;
